import React from 'react'
import styled from 'styled-components'
import { graphql } from 'gatsby'

import Nav from '../../components/nav'
import SEO from '../../components/seo'
import Footer from '../../components/footer'
import config from '../../../data/SiteConfig'
import ChangelogCard from '../../components/changelogCard'
import PulseChangelogIcon from '../../icons/pulseChangelog'
import Box from '../../components/box'
import FollowTwitterSvg from '../../icons/followTwitter'
import SubscribeEmailSvg from '../../icons/subscribeEmail'
import ChangelogListSvg from '../../assets/svg/changelog-list.svg'
import { useRemoveHotjarScript } from '../../utils/hooks/useHotjarScript'

const ChangelogsListing = ({ data }) => {
  useRemoveHotjarScript()

  return (
    <>
      <SEO
        title="Changelog - Pulse"
        description="A continuous stream of changes made to the Pulse platform."
        ogImage="/assets/ogimages/pulse-changelog.png"
        twImage="/assets/ogimages/twitter/pulse-changelog.png"
      />
      <Nav
        theme="light"
        style={{
          background: '#FFFFFF',
          position: 'absolute'
        }}
      />
      <MainContainer>
        <ChangelogContainer>
          <Box>
            <Row>
              <ListLine style={{ marginTop: '8px' }}>
                <PulseChangelogIcon />
                <LineContainer>
                  <Line />
                </LineContainer>
              </ListLine>
              <Box display="flex" flexDirection="column" height="100%">
                <div className='font-semibold text-3xl leading-36 font-display'>
                  Changelog
                </div>
                <div className='mt-14 text-base leading-24' style={{ color: 'rgba(0, 0, 0, 0.6)' }}>
                  A continuous stream of changes made to the Pulse platform.
                </div>
                <Links>
                  <a
                    className="twitter"
                    href="https://www.twitter.com/pulseasync"
                    target="_blank"
                    rel="noreferrer noopener"
                    style={{ color: 'rgba(42, 163, 239, 0.7)' }}
                  >
                    <FollowTwitterSvg fill="#2AA3EF" />
                    Follow on Twitter
                  </a>

                  <a
                    className="subscribe-email"
                    href="https://www.getrevue.co/profile/pulse"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <SubscribeEmailSvg fill="#000000" />
                    Subscribe to updates
                  </a>
                </Links>
              </Box>
            </Row>
            <ChangelogList>
              {data.allMarkdownRemark.edges.map(item => {
                const { node } = item
                const {
                  frontmatter: { title, author, date, cover, slug, tags },
                  excerpt,
                  id,
                } = node
                const {
                  frontmatter: { firstName, lastName, cover: avatar },
                } = author

                const postType = 'changelog'
                const postPrefix = config[`${postType}Prefix`] + '/'
                const to = postPrefix + slug + '/'

                return (
                  <Row>
                    <ListLine>
                      <Date className='text-sm leading-24 text-gray-foreground-400'>{date}</Date>
                      {/* <ChangelogListSvg /> */}
                      <LineContainer>
                        <Line />
                      </LineContainer>
                    </ListLine>
                    <ChangelogCard
                      key={id}
                      to={to}
                      title={title}
                      tags={tags}
                      avatar={avatar}
                      firstName={firstName}
                      lastName={lastName}
                      date={date}
                      cover={cover}
                      excerpt={excerpt}
                    />
                  </Row>
                )
              })}
            </ChangelogList>
          </Box>
        </ChangelogContainer>
      </MainContainer>
      <Footer />
    </>
  )
}

export default ChangelogsListing

export const ChangelogListingQuery = graphql`
  query ChangelogListingQuery($skip: Int!, $limit: Int!) {
    allMarkdownRemark(
      sort: { fields: [fields___date], order: DESC }
      filter: {
        fileAbsolutePath: { regex: "/(content/changelog/).*\\\\.md$/" }
      }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          id
          fields {
            slug
            date
          }
          excerpt
          timeToRead
          frontmatter {
            title
            tags
            cover {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            date(formatString: "MMMM Do, YYYY")
            slug
            author {
              frontmatter {
                firstName
                lastName
                cover {
                  childImageSharp {
                    fluid {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
                slug
                email
                twitterHandle
                bio
              }
            }
          }
        }
      }
    }
  }
`

const MainContainer = styled.div`
  display: flex;
  padding-bottom: 200px;
  margin: auto;
  width: 770px;
  max-width: 95vw;
  height: 100%;

  @media ${props => props.theme.device.laptopL} {
    max-width: 1200px;
  }

  @media ${props => props.theme.mobileL} {
    padding-top: 24px;
  }

  @media ${props => props.theme.device.tablet} {
    padding-top: 56px;
  }
`

const H1 = styled.h1`
  font-family: ${props => props.theme.typography.fontFamilyGTBold};
  font-style: normal;
  font-size: 40px;
  line-height: 48px;
  margin: 0;
  padding: 0;
`

const SubText = styled.h2`
  font-family: ${props => props.theme.typography.fontFamilyGTMono};
  font-size: 16px;
  line-height: 40px;
  color: rgba(0, 0, 0, 0.6);
  margin-top: 12px;
  margin-bottom: 0;
  display: block;
`

const Links = styled.div`
  margin-top: 24px;
  margin-bottom: 88px;
  display: flex;
  flex-direction: column;

  @media ${props => props.theme.device.tablet} {
    flex-direction: row;
  }

  .twitter {
    font-size: 16px;
    line-height: 24px;
    color: #757575;
    display: flex;
    align-items: center;
    cursor: pointer;
    text-decoration: none;
    margin-right: 24px;
    svg {
      margin-right: 8px;
    }
  }

  .subscribe-email {
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #545454;
    text-decoration: none;

    svg {
      margin-right: 8px;
    }
  }
`

const Date = styled.span`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #999999;
  position: absolute;
  top: 2px;
  transform: translateX(-75%);
  width: max-content;
`

const Line = styled.div`
  width: 3px;
  background-color: #f6f6f6;
  height: 100%;
`

const LineContainer = styled.div`
  width: 46px;
  display: flex;
  justify-content: center;
  height: 100%;
`

const ChangelogContainer = styled.main`
  display: flex;
  margin: 32px auto 0 auto;
`

const ChangelogList = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto;
  max-width: 100%;
`

const ListLine = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 24px;
  height: auto;
  width: 46px;
  position: relative;
`

const Row = styled.div`
  display: flex;
`
