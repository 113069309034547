import * as React from 'react'

function SvgComponent(props) {
  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#prefix__clip0)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.29 18.302c7.548 0 11.675-6.412 11.675-11.972 0-.182-.004-.363-.012-.544A8.466 8.466 0 0020 3.608a8.01 8.01 0 01-2.356.661 4.205 4.205 0 001.804-2.327 8.107 8.107 0 01-2.606 1.022 4.052 4.052 0 00-2.995-1.33c-2.266 0-4.103 1.885-4.103 4.208 0 .33.035.651.106.959-3.412-.176-6.435-1.85-8.458-4.396A4.278 4.278 0 00.836 4.52c0 1.46.725 2.749 1.826 3.502a3.997 3.997 0 01-1.858-.526v.053c0 2.039 1.414 3.74 3.291 4.125a4.013 4.013 0 01-1.853.072c.523 1.672 2.037 2.889 3.833 2.923A8.103 8.103 0 010 16.411a11.407 11.407 0 006.29 1.89"
          fill={props.fill | '#fff'}
        />
      </g>
      <defs>
        <clipPath id="prefix__clip0">
          <path fill={props.fill | '#fff'} d="M0 0h20v20H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default SvgComponent
