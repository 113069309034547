import * as React from 'react'

function SvgComponent(props) {
  return (
    <svg
      width={46}
      height={46}
      viewBox="0 0 46 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23 0C10.304 0 0 10.304 0 23s10.304 23 23 23 23-10.304 23-23S35.696 0 23 0zm0 41.4C12.834 41.4 4.6 33.166 4.6 23 4.6 12.834 12.834 4.6 23 4.6c10.166 0 18.4 8.234 18.4 18.4 0 10.166-8.234 18.4-18.4 18.4zM34.5 23c0 6.352-5.148 11.5-11.5 11.5-6.35 0-11.5-5.148-11.5-11.5 0-6.351 5.15-11.5 11.5-11.5 6.352 0 11.5 5.149 11.5 11.5z"
        fill="#000"
      />
    </svg>
  )
}

export default SvgComponent
