import * as React from 'react'

function SvgComponent(props) {
  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.75 3h-14c-.737 0-1.372.462-1.628 1.11l8.631 6.166 8.627-6.162A1.757 1.757 0 0016.75 3zm1.75 2.797l-8.329 5.95a.72.72 0 01-.836 0L1 5.792v9.457c0 .962.788 1.75 1.75 1.75h14c.962 0 1.75-.788 1.75-1.75V5.797z"
        fill={props.fill | '#fff'}
      />
    </svg>
  )
}

export default SvgComponent
