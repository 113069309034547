import React from 'react'
import styled from 'styled-components'
import Img from 'gatsby-image'
import { Link } from 'gatsby'

const Container = styled(Link)`
  display: flex;
  flex-direction: column;
  margin-bottom: 2.5rem;
  text-decoration: none;
  max-width: 700px;
  padding-top: 40px;
  padding-bottom: 41px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
`

const H3 = styled.h3`
  font-family: ${props => props.theme.typography.fontFamilyInter};
  font-style: normal;
  font-weight: bold;
  font-size: 1.25rem;
  line-height: 1.75rem;
  color: #000000;
  margin: 0;
  margin-bottom: 1rem;
`

const Excerpt = styled.span`
  font-family: ${props => props.theme.typography.fontFamilyInter};
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 30px;
  color: ${props => props.theme.core.content.tertiary};
  flex: none;
  order: 2;
  flex-grow: 0;
  /* margin: 1rem 0px; */
`

const Tag = styled.div`
  border: 1px solid ${props => props.theme.core.border.inverseOpaque};
  padding: 4px 8px;
  color: ${props => props.theme.core.content.inversePrimary};
  font-size: 12px;
  line-height: 14.52px;
  letter-spacing: 2px;
  font-weight: 600;
  background: ${props => props.theme.core.background.inversePrimary};
  border-radius: 3px;
  box-sizing: border-box;
  width: fit-content;
  font-feature-settings: 'salt' on;
  text-transform: uppercase;
  margin-right: 8px;
`

const TagContainer = styled.div`
  display: flex;
  margin-top: -4px;
  flex-wrap: wrap;
  margin-bottom: 1rem;
`

const ChangelogCard = ({ to, title, cover, excerpt, tags }) => {
  return (
    <Container to={to}>
      <div className='text-xl leading-28 font-medium text-black mb-16'>{title}</div>
      {tags && tags.length ? (
        <TagContainer>
          {tags.map((tag, index) => (
            <Tag key={index}>{tag}</Tag>
          ))}
        </TagContainer>
      ) : null}
      {cover && cover.childImageSharp && (
        <Img
          fluid={cover.childImageSharp.fluid}
          alt={title}
          style={{ width: '100%', marginBottom: '1rem', borderRadius: '16px' }}
        />
      )}
      <Excerpt>{excerpt}</Excerpt>
    </Container>
  )
}

export default ChangelogCard
